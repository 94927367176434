import { useEffect, useRef, useState } from 'react'

export const useIsStucked = <T extends HTMLElement>(isSticky = false) => {
  const [isStucked, setIsStucked] = useState(false)
  const navbarRef = useRef<T>(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsStucked(e.intersectionRatio < 1)
      },
      { threshold: [1], rootMargin: '-1px 0px 0px 0px' },
    )

    if (isSticky && navbarRef.current) {
      observer.observe(navbarRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [isSticky, navbarRef])

  return {
    isStucked,
    navbarRef,
  }
}
