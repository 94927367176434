import { FC } from 'react'
import gql from 'graphql-tag'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { UserAction_UserFragment } from 'generated/graphql'
import getMaxCount from '../../module/getMaxCount'
import HeaderChatIconDesktop from '../HeaderChatIcon/HeaderChatIcon.desktop'
import UserHeaderLoginActions from './UserHeaderLoginActions'

gql`
  fragment UserAction_user on User {
    id
    scrapCount
    notificationUnreadCount(notificationType: $notificationType)
    ...UserHeaderLoginActions_user
  }
`

interface IFUserActionProps {
  currentUser?: UserAction_UserFragment | null
  loading?: boolean
}

const UserAction: FC<IFUserActionProps> = ({ currentUser, loading }) => {
  if (loading) return <StyledWrapper />

  return (
    <StyledWrapper>
      <nav>
        <Link href="/calendar">
          <a>
            <Image
              width={20}
              height={20}
              src="/static/images/new_main/icon/ic-calendar.png"
              alt=""
            />
            공고 달력
          </a>
        </Link>
        <Link href="/notifications" rel="nosublink">
          <a>
            <Image
              width={20}
              height={20}
              src="/static/images/new_main/icon/ic-alarm.png"
              alt=""
            />
            알림
            <p className="count">
              {getMaxCount(currentUser?.notificationUnreadCount ?? 0)}
            </p>
          </a>
        </Link>
        <Link href="/accounts/scrap" rel="nosublink">
          <a>
            <Image
              width={20}
              height={20}
              src="/static/images/new_main/icon/ic-scrap.png"
              alt=""
            />
            스크랩
            <p className="count">{getMaxCount(currentUser?.scrapCount ?? 0)}</p>
          </a>
        </Link>
        <HeaderChatIconDesktop />
      </nav>
      <UserHeaderLoginActions currentUser={currentUser} loading={loading} />
    </StyledWrapper>
  )
}

export default UserAction

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 47px;
  gap: 16px;
  flex-shrink: 0;

  & > nav {
    display: flex;
    align-items: center;
    gap: 14px;

    & > a {
      display: flex;
      align-items: center;
      gap: 5px;
      font-size: 14px;
      .count {
        color: #ef2929;
      }
    }

    &::after {
      content: '';
      width: 1px;
      height: 14px;
      background-color: #ddd;
      margin-left: 2px;
    }
  }
`
