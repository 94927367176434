import { AnchorHTMLAttributes, FC, ReactNode } from 'react'
import Link, { LinkProps } from 'next/link'
import styled, { css } from 'styled-components'

interface IFProps extends Omit<LinkProps, 'href'> {
  icon: ReactNode
  activityHref: string
  activityLabel: string
  anchorElementProps?: AnchorHTMLAttributes<HTMLAnchorElement>
}

const NavBarLinkWithIcon: FC<IFProps> = ({
  activityHref,
  activityLabel,
  icon,
  anchorElementProps,
  ...restLinkProps
}) => {
  return (
    <StyledWrapper>
      <Link href={activityHref} {...restLinkProps}>
        <a {...anchorElementProps}>
          {icon}
          {activityLabel}
        </a>
      </Link>
    </StyledWrapper>
  )
}

export default NavBarLinkWithIcon

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    height: 24px;
    border: 1px solid #ffffff;
    border-radius: 19px;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 1px;
      height: 12px;
      background-color: #cccccc;
      margin: 0 15px;
    }

    a {
      white-space: nowrap;
      height: 24px;
      font-size: 16px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.64px;
      text-align: left;
      color: ${theme.color.text_1};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  `}
`
