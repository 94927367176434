import { AnchorHTMLAttributes, useState } from 'react'
import { useDomain } from 'context'
import { useRouter } from 'next/router'
import {
  UserHeaderLoginActions_UserFragment,
  UserType,
} from 'generated/graphql'
import useIsWebview from '@app.modules/hooks/useIsWebview'
import { getWebviewHref } from '@app.modules/util'
import { useOpenCustomerMessageForm } from 'components/customer-message-form'
import { AD_CONTACT, MEDIA_INTRODUCTION } from 'constants/urls'
import {
  LINKAREER_ACADEMY_BASE_URL,
  LINKAREER_API_BASE_URL,
  LINKAREER_BASE_URL,
  LINKAREER_BIZ_BASE_URL,
} from 'utils/config'

export enum menuType {
  INTERNAL_LINK = 'internal',
  EXTERNAL_LINK = 'external',
  BUTTON = 'button',
}

type TMenuItem = {
  type: menuType
  label: string
  href?: string
  onClick?: () => void
  attributes?: AnchorHTMLAttributes<HTMLAnchorElement>
}

const useUserActionMenu = (
  currentUser?: UserHeaderLoginActions_UserFragment | null,
) => {
  const router = useRouter()
  const isWebview = useIsWebview()
  const { protocol } = useDomain()
  const openCustomerMessageForm = useOpenCustomerMessageForm()
  const [channelUnavailable, setChannelUnavailable] = useState(false)
  const [channelDeactivated, setChannelDeactivated] = useState(false)

  const onCloseModal = () => {
    setChannelUnavailable(false)
    setChannelDeactivated(false)
  }

  let userMenuList: TMenuItem[] = []

  if (currentUser?.type === UserType.NORMAL) {
    userMenuList = [
      {
        type: menuType.INTERNAL_LINK,
        label: '담당자 Q&A',
        href: `/accounts/reply`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '마이페이지',
        href: `/profile`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '나의 시험 CBT',
        href: `${LINKAREER_ACADEMY_BASE_URL}/mycbt/archive`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '스펙 정리하기',
        href: `/my-career/spec-management?section=info`,
      },
      {
        type: menuType.INTERNAL_LINK,
        label: '내가 쓴 채널 댓글',
        href: `/accounts/channel-comment`,
      },
      {
        type: menuType.BUTTON,
        label: '고객문의',
        onClick: () => {
          openCustomerMessageForm()
        },
      },
      {
        type: menuType.INTERNAL_LINK,
        href: `${LINKAREER_API_BASE_URL}/logout?next=${LINKAREER_BASE_URL}`,
        label: '로그아웃',
      },
    ]
  } else {
    userMenuList = [
      {
        type: menuType.EXTERNAL_LINK,
        label: '공고 등록',
        href: `//${LINKAREER_BIZ_BASE_URL}/register/activity`,
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '공고/지원자 관리',
        href: `//${LINKAREER_BIZ_BASE_URL}/activities/ALL`,
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '회원/기업정보 관리',
        href: `//${LINKAREER_BIZ_BASE_URL}/profile`,
      },
      {
        type: menuType.EXTERNAL_LINK,
        href: `//${LINKAREER_BIZ_BASE_URL}/channel/written-comment`,
        label: '내가 쓴 채널 댓글',
      },
      {
        type: menuType.BUTTON,
        label: '채널 관리',
        onClick: () => {
          if (!currentUser?.channel) {
            return setChannelUnavailable(true)
          }
          if (currentUser.channel.deactivatedAt) {
            return setChannelDeactivated(true)
          }
          router.push(`${protocol}://${LINKAREER_BIZ_BASE_URL}/channel`)
        },
      },
      {
        type: menuType.BUTTON,
        label: '내 채널 바로가기',
        onClick: () => {
          if (!currentUser?.channel) {
            return setChannelUnavailable(true)
          }
          router.push(
            getWebviewHref(`/channel/${currentUser.channel?.url}`, isWebview),
          )
        },
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '광고 제휴문의',
        href: AD_CONTACT,
        attributes: { target: '_blank' },
      },
      {
        type: menuType.EXTERNAL_LINK,
        label: '매체소개서 다운로드',
        href: MEDIA_INTRODUCTION,
        attributes: {
          target: '_blank',
          download: true,
        },
      },
      {
        type: menuType.EXTERNAL_LINK,
        href: `${LINKAREER_API_BASE_URL}/logout?next=${LINKAREER_BASE_URL}`,
        label: '로그아웃',
      },
    ]
  }

  return {
    userMenuList,
    channelUnavailable,
    channelDeactivated,
    onCloseModal,
  }
}

export default useUserActionMenu
