import * as React from 'react'
import styled from 'styled-components'
import { Close } from 'svgIcons'
import Button from '@app.components/Button'
import Modal, {
  IFModalProps,
  createOpenModal,
} from 'app.components/Modal/Modal'
import { mobileBreakPoint } from 'app.styled/media'
import { LINKAREER_BIZ_BASE_URL } from 'utils/config'

interface ChannelDeactivatedModalProps extends IFModalProps {
  onConfirm?: () => void
}

const ChannelDeactivatedModal: React.FC<ChannelDeactivatedModalProps> = ({
  onClose,
  ...props
}) => {
  const handleClickConfirm = () => {
    window.open(`https://${LINKAREER_BIZ_BASE_URL}/profile`)
    onClose && onClose()
  }
  return (
    <Modal onClose={onClose} {...props}>
      <StyledWrapper className="common-modal">
        <div className="close-btn" onClick={onClose}>
          <Close />
        </div>
        <div className="modal-title">채널 서비스 이용 불가</div>
        <div className="modal-description">
          사업자등록번호가 올바르지 않아 채널 서비스 이용이 어렵습니다. <br />
          아래 사업자 등록번호 확인하기 버튼을 클릭하여 수정해주세요.
        </div>
        <div className="btn-list">
          <Button className="btn" onClick={handleClickConfirm}>
            사업자 등록번호 확인하기
          </Button>
        </div>
      </StyledWrapper>
    </Modal>
  )
}

export default ChannelDeactivatedModal

const openChannelDeactivatedModal = createOpenModal(ChannelDeactivatedModal)
export { openChannelDeactivatedModal }

const StyledWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  .close-btn {
    align-self: flex-end;
    color: #999999;
    cursor: pointer;
  }
  .modal-title {
    font-size: 24px;
    font-weight: 600;
  }
  .modal-description {
    font-size: 16px;
    margin: 20px 0 30px;
    text-align: center;
  }
  .btn-list {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
    .btn {
      font-size: 16px;
      padding: 13px 30px;
      &.cancle {
        background-color: #bbbbbb;
      }
    }
  }
  ${mobileBreakPoint} {
    max-width: 350px;
  }
`
