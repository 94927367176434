import React, { FC } from 'react'
import TSvgProps from 'svgIcons/type/svgPropsType'

const CareerPageNavLinkIcon: FC<TSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      data-name="스펙 정리하기 링크"
      viewBox="0 0 20 20"
      {...props}
    >
      <path fill="none" d="M0 0h20v20H0z" data-name="사각형 24987"></path>
      <g fill="#fff" stroke="#1530ff" data-name="그룹 36741">
        <g
          strokeWidth="2"
          data-name="사각형 24983"
          transform="translate(4 1.5)"
        >
          <rect width="12" height="8" stroke="none" rx="2"></rect>
          <rect width="10" height="6" x="1" y="1" fill="none" rx="1"></rect>
        </g>
        <g
          strokeWidth="2"
          data-name="사각형 24984"
          transform="translate(.5 4.5)"
        >
          <rect width="19" height="14" stroke="none" rx="3"></rect>
          <rect width="17" height="12" x="1" y="1" fill="none" rx="2"></rect>
        </g>
        <g data-name="사각형 24985">
          <path stroke="none" d="M3.25 4.5h1.5v13.586h-1.5z"></path>
          <path fill="none" d="M3.75 5h.5v12.586h-.5z"></path>
        </g>
        <g data-name="사각형 24986">
          <path stroke="none" d="M15 4.5h1.5v13.586H15z"></path>
          <path fill="none" d="M15.5 5h.5v12.586h-.5z"></path>
        </g>
      </g>
    </svg>
  )
}

export default CareerPageNavLinkIcon
