import React from 'react'

function LinkareerLogoNoName() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <desc>앱 설치하기 QRCode Link</desc>
      <linearGradient
        id="a"
        x1="0.5"
        x2="0.5"
        y1="0.435"
        y2="1"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#01a0ff"></stop>
        <stop offset="1" stopColor="#0280ff"></stop>
      </linearGradient>
      <rect width="20" height="20" fill="url(#a)" rx="2"></rect>
      <path
        fill="#fff"
        d="M6.428 16.205h7.144v-2.829H9.279V3.994H6.428z"
      ></path>
    </svg>
  )
}

export default LinkareerLogoNoName
