import { FC, ReactNode } from 'react'
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import { DialogProps } from '@material-ui/core/Dialog'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CloseIcon from 'svgIcons/Close'

export interface AlertDialogProps {
  title: ReactNode
  description?: ReactNode
  dismissLabel?: string
  confirmLabel?: string
  onConfirm?(): void
  onRequestClose(): void
  disabled?: boolean
  loading?: boolean

  DialogProps: Omit<DialogProps, 'onClose'>
  closeIcon?: ReactNode
}

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
    },
    wrapper: {
      position: 'relative',
      width: '100%',
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    title: {
      padding: '50px',
      paddingBottom: 0,
    },
    actions: {
      paddingBottom: '50px',
    },
    closeIcon: {
      width: '16px',
      height: '16px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
    button: {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
    },
  }),
)

const AlertDialog: FC<AlertDialogProps> = (props) => {
  const classes = useStyles()
  const {
    title,
    description,
    dismissLabel,
    confirmLabel,
    onRequestClose,
    onConfirm,
    disabled = false,
    loading = false,
    DialogProps,
    closeIcon = <CloseIcon className={classes.closeIcon} />,
  } = props

  return (
    <Dialog
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...DialogProps}
      onClose={disabled ? undefined : onRequestClose}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle id="alert-dialog-title" className={classes.title}>
        {title}
      </DialogTitle>
      {closeIcon && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onRequestClose}
        >
          {closeIcon}
        </IconButton>
      )}
      {description && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        {dismissLabel && (
          <Button
            data-testid="alert-dialog-dismiss-button"
            disabled={disabled || loading}
            variant="contained"
            onClick={onRequestClose}
            className={classes.button}
            fullWidth
          >
            {dismissLabel || '취소'}
          </Button>
        )}
        <div className={classes.wrapper}>
          {confirmLabel && (
            <Button
              data-testid="alert-dialog-confirm-button"
              disabled={disabled || loading}
              variant="contained"
              onClick={onConfirm}
              color="primary"
              autoFocus
              className={classes.button}
              fullWidth
            >
              {confirmLabel || '확인'}
            </Button>
          )}
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
