import { FC, Fragment, MouseEvent, useState } from 'react'
import Link, { LinkProps } from 'next/link'
import styled from 'styled-components'
import { UrlObject } from 'url'
import { UserType } from 'generated/graphql'
import NotManagerModal from 'components/NotManagerModal'
import CareerPageNavLinkIcon from 'svgIcons/career/CareerPageNavLinkIcon'

interface IFProps extends Omit<LinkProps, 'href'> {
  activityHref: UrlObject
  activityLabel: string
  userType?: UserType
}

const SpecManageButton: FC<IFProps> = ({
  activityHref,
  activityLabel,
  userType,
  ...restLinkProps
}) => {
  const [showManagerAlert, setShowManagerAlert] = useState<boolean>(false)

  const handleClickManagerAlert = (e: MouseEvent<HTMLAnchorElement>) => {
    if (userType && userType !== UserType.NORMAL) {
      setShowManagerAlert(true)
      e.preventDefault()
    }
  }

  const handleCloseModal = () => {
    setShowManagerAlert(false)
  }

  return (
    <Fragment>
      <Link href={activityHref} {...restLinkProps}>
        <StyledWrapper
          className="register-activity"
          onClick={handleClickManagerAlert}
        >
          <CareerPageNavLinkIcon width={20} height={20} />
          <span>{activityLabel}</span>
        </StyledWrapper>
      </Link>
      <NotManagerModal
        DialogProps={{ open: showManagerAlert }}
        onRequestClose={handleCloseModal}
        onConfirm={handleCloseModal}
      />
    </Fragment>
  )
}

export default SpecManageButton

const StyledWrapper = styled.a`
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  letter-spacing: -0.64px;
  white-space: nowrap;
  text-align: left;
  color: #1530ff;

  &:hover {
    color: #1530ff; // 전역 스타일 무시
  }
`
