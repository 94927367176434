import { FC } from 'react'
import styled from 'styled-components'
import AlertDialog, { AlertDialogProps } from 'components/AlertDialog'

type IFProps = Partial<AlertDialogProps> &
  Pick<AlertDialogProps, 'onRequestClose' | 'DialogProps'> & {
    chatRoomId?: string
  }

const NotManagerModal: FC<IFProps> = (props) => {
  return (
    <AlertDialog
      title={
        <StyledWrapper>
          <p>개인회원만 이용할 수 있는 서비스입니다.</p>
        </StyledWrapper>
      }
      confirmLabel="확인"
      {...props}
    />
  )
}

export default NotManagerModal

const StyledWrapper = styled.div`
  & > p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
`
