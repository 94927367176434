import { FC } from 'react'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import LinkareerLogoNoName from 'svgIcons/LinkareerLogoNoName'

const AppInstallQRCode: FC = () => {
  return (
    <StyledWrapper>
      <LinkareerLogoNoName />
      <p className="nav-bar-title">링커리어 앱 설치</p>
      <div className="qr-popup">
        <div className="popup-text">
          <p className="text-primary">앱 설치하고 공고 마감 D-1</p>
          <p className="text-default">알림을 받아보세요!</p>
        </div>
        <div className="qr-image">
          <figure>
            <figcaption>앱 설치 QR코드</figcaption>
            <Image
              src="/static/images/qr-code-link/qr-code-link.png"
              width={130}
              height={130}
              alt="qr_code_link_image"
              loading="lazy"
            />
          </figure>
        </div>
      </div>
    </StyledWrapper>
  )
}

export default AppInstallQRCode

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 120px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    .nav-bar-title {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: -0.56px;
      color: ${theme.color.primary_2};
    }

    .qr-popup {
      width: 444px;
      height: 190px;
      padding: 30px;
      filter: drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.16));
      border: solid 1px ${theme.line.line_2};
      border-radius: 10px;
      background-color: #ffffff;
      position: absolute;
      z-index: 200;
      top: 52px;
      display: none;
      align-items: center;

      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background-color: #ffffff;
        position: absolute;
        top: -8px;
        transform: rotate(45deg);
        border-left: solid 1px ${theme.line.line_2};
        border-top: solid 1px ${theme.line.line_2};
      }

      .popup-text {
        width: 229px;

        p {
          font-size: 22px;
          font-weight: 500;
          line-height: 1.55;
          letter-spacing: -0.88px;

          &.text-primary {
            color: ${theme.color.primary_2};
          }
          &.text-default {
            color: ${theme.color.text_1};
          }
        }
      }

      .qr-image {
        width: 130px;
        height: 130px;
        margin-left: 21px;
        background-color: ${theme.line.line_2};

        figure {
          width: 100%;
          height: 100%;
          figcaption {
            font-size: 0;
            height: 0;
            width: 0;
          }
        }
      }
    }

    &:hover {
      .qr-popup {
        display: flex;
      }
    }
  `}
`
