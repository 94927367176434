import { useCookies } from 'react-cookie'
import { addDays } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { TIMEZONE } from 'utils/config'

export const CHAT_TOOLTIP_COOKIE = 'chat-tooltip'

const useChatCookies = () => {
  const [_, setCookies] = useCookies()

  const getCookieDomain = () => {
    const url = window.location.href

    if (url.includes('linkareer')) return '.linkareer.com'
    if (url.includes('lnkr')) return '.lnkr.cc'
    return 'localhost'
  }

  const setChatCookie = (cookie: string, expires: Date) => {
    setCookies(cookie, Date.now(), {
      expires,
      domain: getCookieDomain(),
      path: '/',
    })
  }

  const addChatTooltipCookie = () => {
    const oneDay = zonedTimeToUtc(addDays(new Date(), 7), TIMEZONE)

    setChatCookie(CHAT_TOOLTIP_COOKIE, oneDay)
  }

  return {
    addChatTooltipCookie,
  }
}

export default useChatCookies
