export const convertStringToContent = (content: string) => {
  const convertedContent = content.split('\n').map((line, idx) => {
    return (
      <span key={idx}>
        {line}
        <br />
      </span>
    )
  })
  return convertedContent
}

export const getWebviewHref = (href: string, isWebview = false) => {
  if (!isWebview) {
    return href
  }
  return `/webview` + href
}

export const generateOnChangeEvent = (name: string, value: string | any[]) => {
  const event: any = {
    target: {
      name,
      value,
    },
  }
  return event
}

export const getTextFirstLine = (text: string): string => {
  if (!text?.includes('\n') && text?.length > 160) {
    return text?.slice(0, 160)
  }

  return text?.split('\n')[0]
}

export const parseDomainFromUrl = (url: string): string => {
  let domain = ''
  try {
    domain = new URL(url).hostname.replace('www.', '')
  } catch (error) {
    console.error(error)
  }
  return domain
}

export const parseHashtagFromContentHtml = (content: string): string[] => {
  const elementForParseHashtags = document.createElement('div')
  elementForParseHashtags.innerHTML = content
  const hashtagDivArray = Array.from(
    elementForParseHashtags.getElementsByClassName('ql-hashtag'),
  )
  const hashtagArray = hashtagDivArray.map((hashtagElement) => {
    return (
      hashtagElement
        .getElementsByTagName('span')?.[0]
        ?.innerText?.replace('#', '') || null
    )
  })

  return hashtagArray.filter(
    (hashtag) => hashtag !== null && hashtag !== '',
  ) as string[]
}
