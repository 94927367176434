import { FC } from 'react'
import { useDomain } from 'context'
import Link from 'next/link'
import styled from 'styled-components'
import { LINKAREER_BIZ_BASE_URL } from 'utils/config'

const UserLoginMenu: FC = () => {
  const { protocol } = useDomain()

  return (
    <StyledWrapper>
      <Link href="/login" rel="nosublink">
        <a>개인회원</a>
      </Link>
      <a
        className="corporate"
        href={`${protocol}://${LINKAREER_BIZ_BASE_URL}`}
        rel="nosublink"
      >
        기업서비스
      </a>
    </StyledWrapper>
  )
}

export default UserLoginMenu

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  a {
    font-size: 14px;
    color: #333;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  .corporate {
    width: 92px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #01a0ff;
    border: solid 1px #01a0ff;
    border-radius: 14px;
  }
`
