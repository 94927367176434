import { FC } from 'react'
import styled from 'styled-components'
import { Close } from 'svgIcons'
import useChatCookies from '@app.feature/chatting/chattingContext/hook/useChatCookies'

const HeaderChatTooltipDesktop: FC = () => {
  const { addChatTooltipCookie } = useChatCookies()

  return (
    <StyledWrapper onClick={addChatTooltipCookie}>
      <div className="text">
        채팅방에서 실시간으로 채용 정보를 공유해보세요!
      </div>
      <Close className="close" />
    </StyledWrapper>
  )
}

export default HeaderChatTooltipDesktop

const StyledWrapper = styled.div`
  position: absolute;
  bottom: -66px;
  right: -140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 330px;
  padding: 11px 15px;
  border-radius: 8px;
  background-color: #003056;
  ::after {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid #003056;
    transform: translateX(-50%);
    z-index: 1;
  }
  ::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 50%;
    border: 10px solid transparent;
    border-bottom: 10px solid #003056;
    transform: translateX(-50%);
    z-index: 0;
  }
  .text {
    font-size: 14px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: -0.56px;
    user-select: none;
    color: #fff;
  }
  .close {
    width: 11px;
    fill: #fff;
  }
`
