import { FC } from 'react'
import styled from 'styled-components'

const NotSupportedBrowser: FC = () => {
  return (
    <StyledWrapper id="not-supported-browser">
      <p className="header">
        Internet Explorer <b>서비스 종료</b> 안내
      </p>
      <div className="body">
        <p>
          Internet Explorer(IE) 11 및 이전 버전에 대한 지원이 종료되었습니다.
        </p>
        <p>
          원활한 이용을 위해 Chrome, Microsoft Edge, Safari, Whale 등의
          브라우저로 접속해주세요.
        </p>
      </div>
    </StyledWrapper>
  )
}

export default NotSupportedBrowser

const StyledWrapper = styled.div`
  display: none;
  padding: 26px;
  background-color: #212121;
  color: #fff;
  text-align: center;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    display: block;
  }
  .header {
    font-size: 26px;
    margin-bottom: 10px;
  }
  .body {
    font-size: 18px;
  }
`
