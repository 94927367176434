import { FC } from 'react'
import { useCookies } from 'react-cookie'
import gql from 'graphql-tag'
import Image from 'next/image'
import styled from 'styled-components'
import { useGqlChatMessageUnreadCountQuery } from 'generated/graphql'
import { CHAT_TOOLTIP_COOKIE } from '@app.feature/chatting/chattingContext/hook/useChatCookies'
import { useChattingContext } from '@app.feature/chatting/chattingContext/module/ChattingContext'
import getMaxCount from '../../module/getMaxCount'
import HeaderChatTooltipDesktop from './HeaderChatTooltip.desktop'

gql`
  query gqlChatMessageUnreadCount {
    chatMessageUnreadCount
  }
`

const HeaderChatIconDesktop: FC = () => {
  const { isOpen, openChatting } = useChattingContext()
  const [cookies] = useCookies()
  const { data, error } = useGqlChatMessageUnreadCountQuery()

  const getUnreadChatMessageCount = () => {
    if (error) return null

    if (data) {
      return (
        <p className="count">
          {getMaxCount(data?.chatMessageUnreadCount ?? 0)}
        </p>
      )
    }
  }

  return (
    <StyledWrapper>
      <div className="chat-icon" onClick={() => openChatting()}>
        <Image
          src="/static/images/chat/chat-icon.svg"
          alt="채팅"
          width={18}
          height={18}
        />
        <span data-is-active={isOpen}>채팅</span>
        {getUnreadChatMessageCount()}
      </div>
      {!cookies?.[CHAT_TOOLTIP_COOKIE] && <HeaderChatTooltipDesktop />}
    </StyledWrapper>
  )
}

export default HeaderChatIconDesktop

const StyledWrapper = styled.div`
  position: relative;
  z-index: 200;
  cursor: pointer;
  .chat-icon {
    display: flex;
    align-items: center;
    gap: 5px;
    span {
      font-size: 14px;
      user-select: none;
      &[data-is-active='true'] {
        color: #01a0ff;
      }
    }
  }
  .count {
    color: #ef2929;
  }
`
